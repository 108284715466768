/**
 * External dependencies.
 */
import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { AppProvider } from "../components/context/AppContext";


/**
 * Internal dependencies.
 */
import { client } from './client';
import {CartProvider} from "../components/context/cart-context";
import {Script} from "gatsby";

/**
 * Root Element which is a wrapper to the app.
 *
 * @param {Object} element App element.
 *
 * @return {*}
 */
export const wrapPageElement = ( { element } ) => (

        <ApolloProvider client={ client }>
            <AppProvider>
                <CartProvider>
                    <>
{/*                        <Script strategy="off-main-thread" id="gtm-async">*/}
{/*                            {`*/}
{/*        (function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;*/}
{/*            h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};*/}
{/*            (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;*/}
{/*        })(window,document.documentElement,'async-hide','dataLayer',4000,*/}
{/*            {'GTM-NNQBFSN':true});*/}
{/*        `}*/}
{/*                        </Script>*/}
{/*                        <Script id="gtm-init" strategy="off-main-thread" forward={[`dataLayer.push`]}>*/}
{/*                            {`*/}
{/*          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':*/}
{/*new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],*/}
{/*j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=*/}
{/*'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);*/}
{/*})(window,document,'script','dataLayer','GTM-NNQBFSN');*/}
{/*        `}*/}
{/*                        </Script>*/}


                        {/*                <Script>*/}
                        {/*                    {`*/}
                        {/*(function(a,s,y,n,c,h,i,d,e){s.className+=' '+y;h.start=1*new Date;*/}
                        {/*    h.end=i=function(){s.className=s.className.replace(RegExp(' ?'+y),'')};*/}
                        {/*    (a[n]=a[n]||[]).hide=h;setTimeout(function(){i();h.end=null},c);h.timeout=c;*/}
                        {/*})(window,document.documentElement,'async-hide','dataLayer',4000,*/}
                        {/*    {'GTM-NNQBFSN':true});*/}
                        {/*`}*/}
                        {/*                </Script>*/}
                        {/*                        <Script>*/}
                        {/*                            {`*/}
                        {/*          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':*/}
                        {/*new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],*/}
                        {/*j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=*/}
                        {/*'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);*/}
                        {/*})(window,document,'script','dataLayer','GTM-NNQBFSN');*/}
                        {/*        `}*/}
                        {/*                        </Script>*/}


  {/*                      <Script*/}
  {/*                          src={`https://www.googletagmanager.com/gtm.js?id=TM-NNQBFSN`}*/}
  {/*                          strategy="off-main-thread"*/}
  {/*                          forward={[`dataLayer.push`]}*/}
  {/*                      />*/}
  {/*                      <Script id="gtm-init" strategy="off-main-thread">*/}
  {/*                          {`*/}
  {/*  window.dataLayer = window.dataLayer || []*/}
  {/*  window.dataLayer.push({ 'gtm.start': new Date().getTime(), 'event': 'gtm.js' })*/}
  {/*`}*/}
  {/*                      </Script>*/}


  {/*                      <Script strategy="idle">{`window.$czater = {tok: "dd08f520504c4eca2c9745321b26c6f00b679e2c", domain: "https://www.czater.pl/", login: undefined, email: undefined}`}</Script>*/}
  {/*                      <Script strategy="idle" src="https://www.czater.pl/assets/modules/chat/js/chat.js" defer></Script>*/}
  {/*                      <Script type="text/javascript" strategy="off-main-thread" forward={[`clarity.push`]}>*/}
  {/*                          {`(function(c,l,a,r,i,t,y){*/}
  {/*            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};*/}
  {/*            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;*/}
  {/*            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);*/}
  {/*        })(window, document, "clarity", "script", "exo12hzukq");`}*/}
  {/*                      </Script>*/}
  {/*                      <Script id="fb-config" strategy="off-main-thread" forward={[`fbq`]}>*/}
  {/*                          {`setTimeout(function(){*/}
  {/*      !function(f,b,e,v,n,t,s)*/}
  {/*{if(f.fbq)return;n=f.fbq=function(){n.callMethod?*/}
  {/*n.callMethod.apply(n,arguments):n.queue.push(arguments)};*/}
  {/*if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';*/}
  {/*n.queue=[];t=b.createElement(e);t.async=!0;*/}
  {/*t.src=v;s=b.getElementsByTagName(e)[0];*/}
  {/*s.parentNode.insertBefore(t,s)}(window, document,'script',*/}
  {/*'https://connect.facebook.net/en_US/fbevents.js');*/}
  {/*fbq('init', '104201806710190');*/}
  {/*fbq('track', 'PageView');*/}
  {/*      }, 3000);`}*/}
  {/*                      </Script>*/}
  {/*                      <iframe style={{display: "none",visibility:"hidden"}} height="0" width="0"*/}
  {/*                              src="https://www.googletagmanager.com/ns.html?id=GTM-NNQBFSN" title="GTM"></iframe>*/}
                        <div>{ element }</div>
                    </>
                </CartProvider>
            </AppProvider>
        </ApolloProvider>
);