// import React, {useState, useEffect} from "react";
//
// // const defaultCart = {
// //     items: [],
// //     cartCount: 0,
// //     addToCart: function (product) { },
// //     removeFromCart: function (productId) { }
// // };
// //
// // const CartContext = React.createContext(defaultCart);
// //
// // export default CartContext
//
// export const CartContext = React.createContext({
//     productInCart: null,
//     addProduct: (newProduct) => {},
//     dodatekInCart: null,
//     addDodatek: (newDodatek) => {},
//     removeDodatek: (newDodatek) => {},
//     busyMutation: false,
//     setBusyMutation: (state) => {},
//     openPricingTable: false,
//     setopenPricingTable: (state) => {},
//     clearStorage: () => {}
// })
//
// const isBrowser = typeof window !== "undefined"
//
// export const CartProvider = ({children}) => {
//     // const [pakiet, setPakiet] = useState(null)
//     // const [dodatek, setDodatek] = useState([])
//     // const [busyMutation, setBusyMutation] = useState(false)
//     // const [openPricingTable, setopenPricingTable] = useState(false)
//
//     // Pobieranie danych z localStorage przy pierwszym renderowaniu
//     const [pakiet, setPakiet] = useState(() => {
//         if (isBrowser) {
//             return JSON.parse(localStorage.getItem('pakiet')) || null;
//         }
//     });
//     const [dodatek, setDodatek] = useState(() => {
//         if (isBrowser) {
//             return JSON.parse(localStorage.getItem('dodatek')) || [];
//         }
//     });
//     const [busyMutation, setBusyMutation] = useState(() => {
//         if (isBrowser) {
//             return JSON.parse(localStorage.getItem('busyMutation')) || false;
//         }
//     });
//     const [openPricingTable, setopenPricingTable] = useState(() => {
//         if (isBrowser) {
//             return JSON.parse(localStorage.getItem('openPricingTable')) || false;
//         }
//     });
//
//     // Zapisywanie danych do localStorage przy każdej zmianie stanu
//     useEffect(() => {
//         localStorage.setItem('pakiet', JSON.stringify(pakiet));
//     }, [pakiet]);
//
//     useEffect(() => {
//         localStorage.setItem('dodatek', JSON.stringify(dodatek));
//     }, [dodatek]);
//
//     useEffect(() => {
//         localStorage.setItem('busyMutation', JSON.stringify(busyMutation));
//     }, [busyMutation]);
//
//     useEffect(() => {
//         localStorage.setItem('openPricingTable', JSON.stringify(openPricingTable));
//     }, [openPricingTable]);
//
//
//     console.log(`pakiet w kontekscie: ${pakiet}`)
//     console.log(`dodatek w kontekscie: ${dodatek}`)
//
//     const handleMutation = (state) => {
//         setBusyMutation(state)
//     }
//
//     const openPricingtable = (state) => {
//         setopenPricingTable(state)
//     }
//
//     const handleSetPakiet = (p) => {
//         setPakiet(p)
//     }
//
//     const handleSetDodatek = (dodatek) => {
//         setDodatek(dodatkiIds => ([
//             ...dodatkiIds,
//             dodatek
//         ]))
//     }
//
//     const handleRemoveDodatek = (id) => {
//         setDodatek((current) =>
//             current.filter(dodatek =>
//                 dodatek !== id
//             )
//         )
//     }
//
//     const clearStorage = () => {
//         console.log("Czyszcze storage");
//         setPakiet(null);
//         setDodatek([]);
//         setBusyMutation(false);
//         setopenPricingTable(false);
//         localStorage.removeItem('pakiet');
//         localStorage.removeItem('dodatek');
//         localStorage.removeItem('busyMutation');
//         localStorage.removeItem('openPricingTable');
//     }
//
//     const contextValue = {
//         productInCart: pakiet,
//         addProduct: handleSetPakiet,
//         dodatekInCart: dodatek,
//         addDodatek: handleSetDodatek,
//         removeDodatek: handleRemoveDodatek,
//         busyMutation: busyMutation,
//         setBusyMutation: handleMutation,
//         openPricingTable: openPricingTable,
//         setopenPricingTable: openPricingtable,
//         clearStorage: clearStorage
//     }
//
//     return (
//         <CartContext.Provider value={contextValue}>
//             {children}
//         </CartContext.Provider>
//     )
// }




import React, { useState, useEffect } from 'react';

export const CartContext = React.createContext({
    productInCart: null,
    addProduct: (newProduct) => {},
    dodatekInCart: null,
    addDodatek: (newDodatek) => {},
    removeDodatek: (id) => {},
    busyMutation: false,
    setBusyMutation: (state) => {},
    openPricingTable: false,
    setopenPricingTable: (state) => {},
    clearStorage: () => {}
});

const isBrowser = typeof window !== "undefined";

export const CartProvider = ({ children }) => {
    const [pakiet, setPakiet] = useState(null);  // Początkowo null, aby uniknąć problemu z hydratacją
    const [dodatek, setDodatek] = useState([]);
    const [busyMutation, setBusyMutation] = useState(false);
    const [openPricingTable, setopenPricingTable] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false); // Nowy stan do kontrolowania, kiedy dane są załadowane

    // Pobieranie danych z localStorage tylko po załadowaniu klienta
    useEffect(() => {
        if (isBrowser) {
            const savedPakiet = localStorage.getItem('pakiet');
            const savedDodatek = localStorage.getItem('dodatek');
            const savedBusyMutation = localStorage.getItem('busyMutation');
            const savedOpenPricingTable = localStorage.getItem('openPricingTable');

            if (savedPakiet) setPakiet(JSON.parse(savedPakiet));
            if (savedDodatek) setDodatek(JSON.parse(savedDodatek) || []);
            if (savedBusyMutation) setBusyMutation(JSON.parse(savedBusyMutation));
            if (savedOpenPricingTable) setopenPricingTable(JSON.parse(savedOpenPricingTable));

            setIsLoaded(true); // Ustawienie, że dane są załadowane
        }
    }, []);

    // Zapisywanie danych do localStorage przy każdej zmianie stanu
    useEffect(() => {
        if (isBrowser && pakiet !== null) {
            localStorage.setItem('pakiet', JSON.stringify(pakiet));
        }
    }, [pakiet]);

    useEffect(() => {
        if (isBrowser) {
            localStorage.setItem('dodatek', JSON.stringify(dodatek));
        }
    }, [dodatek]);

    useEffect(() => {
        if (isBrowser) {
            localStorage.setItem('busyMutation', JSON.stringify(busyMutation));
        }
    }, [busyMutation]);

    useEffect(() => {
        if (isBrowser) {
            localStorage.setItem('openPricingTable', JSON.stringify(openPricingTable));
        }
    }, [openPricingTable]);

    const handleSetPakiet = (newPakiet) => {
        setPakiet(newPakiet);
    };

    const handleSetDodatek = (newDodatek) => {
        setDodatek((currentDodatki) => [...currentDodatki, newDodatek]);
    };

    const handleRemoveDodatek = (id) => {
        setDodatek((currentDodatki) => currentDodatki.filter(dodatek => dodatek !== id));
    };

    const handleMutation = (state) => {
        setBusyMutation(state);
    };

    const handleOpenPricingTable = (state) => {
        setopenPricingTable(state);
    };

    const clearStorage = () => {
        console.log("Czyszczenie localStorage");
        setPakiet(null);
        setDodatek([]);
        setBusyMutation(false);
        setopenPricingTable(false);
        if (isBrowser) {
            localStorage.removeItem('pakiet');
            localStorage.removeItem('dodatek');
            localStorage.removeItem('busyMutation');
            localStorage.removeItem('openPricingTable');
        }
    };

    const contextValue = {
        productInCart: pakiet,
        addProduct: handleSetPakiet,
        dodatekInCart: dodatek,
        addDodatek: handleSetDodatek,
        removeDodatek: handleRemoveDodatek,
        busyMutation,
        setBusyMutation: handleMutation,
        openPricingTable,
        setopenPricingTable: handleOpenPricingTable,
        clearStorage
    };

    // Renderowanie dzieci tylko wtedy, gdy dane są załadowane
    if (!isLoaded) {
        return null; // Możesz tu dodać np. spinner, jeśli chcesz pokazać użytkownikowi, że dane się ładują
    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};
