// import fetch from 'isomorphic-fetch';
// import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink } from '@apollo/client';
// import { isEmpty } from 'lodash';
//
// // if ( typeof window === `undefined` ) {
// // 	console.warn( 'hey', process.env.WORDPRESS_SITE_URL );
// // }
//
// /**
//  * Middleware operation
//  *
//  * If we have a session token in localStorage, add it to the GraphQL request as a Session header.
//  * If we have a auth token in localStorage, add it to the GraphQL request as a authorization header.
//  */
// export const middleware = new ApolloLink( ( operation, forward ) => {
//
//     let headersData = null;
//
//     /**
//      * If session data exist in local storage, set value as session header.
//      */
//     // const session = ( typeof window !== 'undefined' ) ?  localStorage.getItem( "woo-session" ) : null;
//     const session = localStorage.getItem( "woo-session" );
//
//     if ( ! isEmpty( session ) ) {
//         headersData = {
//             "woocommerce-session": `Session ${ session }`
//         };
//     }
//
//     /**
//      * If auth token exist in local storage, set value as authorization header.
//      */
//     const auth = JSON.parse( localStorage.getItem( "auth" ) );
//     // const auth = ( typeof window !== 'undefined' ) ?  JSON.parse( localStorage.getItem( "auth" ) ) : null;
//     const token = ( ! isEmpty( auth ) ) ? auth.authToken : null;
//
//     if ( ! isEmpty( token ) ) {
//         headersData = {
//             ...headersData,
//             "authorization": token ? `Bearer ${token}` : "",
//         };
//     }
//
//     if ( ! isEmpty( headersData ) ) {
//         operation.setContext( ( { headers = {} } ) => ( {
//             headers: headersData,
//         } ) );
//     }
//
//     return forward( operation );
//
// } );
//
// /**
//  * Afterware operation.
//  *
//  * This catches the incoming session token and stores it in localStorage, for future GraphQL requests.
//  */
// export const afterware = new ApolloLink( ( operation, forward ) => {
//
//     return forward( operation ).map( response => {
//         /**
//          * Check for session header and update session in local storage accordingly.
//          */
//         const context = operation.getContext();
//         const { response: { headers } }  = context;
//         const session = headers.get( "woocommerce-session" );
//
//         if ( session ) {
//
//             // Remove session data if session destroyed.
//             if ( "false" === session ) {
//
//                 localStorage.removeItem( "woo-session" );
//
//                 // Update session new data if changed.
//             } else if ( localStorage.getItem( "woo-session" ) !== session ) {
//
//                 localStorage.setItem( "woo-session", headers.get( "woocommerce-session" ) );
//
//             }
//         }
//
//         return response;
//
//     } );
// } );
//
// // Apollo GraphQL client.
// export const client = new ApolloClient({
//     link: middleware.concat( afterware.concat( createHttpLink({
//         uri: `${process.env.WORDPRESS_SITE_URL}/graphql`,
//         fetch: fetch
//     }) ) ),
//     cache: new InMemoryCache()
// });




import { ApolloClient, InMemoryCache, from, ApolloLink, HttpLink } from '@apollo/client';
import fetch from 'isomorphic-fetch';
import {BatchHttpLink} from "@apollo/client/link/batch-http";

const httpLink = new BatchHttpLink({
    uri: 'https://zamowieniamotocontroler.pl/graphql',
    // uri: 'http://zaplecze-export.bosque-creative.atthost24.pl/graphql',
    // uri: 'http://mc-zaplecze.bosque-creative.atthost24.pl/graphql',
    batchMax: 50,
    batchInterval: 30,
    fetch
});

/**
 * Session handler - if we have a session token in localStorage, add it to the GraphQL request as a Session header.
 *
 * @ref https://github.com/wp-graphql/wp-graphql-woocommerce/pull/88
 */
export const wooSessionMiddleware = new ApolloLink((operation, forward) => {
    // Don't run this while Gatsby is building. Window is not defined
    // @ref: https://www.gatsbyjs.org/docs/debugging-html-builds/
    if (typeof window === 'undefined') {
        return forward(operation)
    }

    /**
     * If session data exists in local storage, set value as session header.
     */
    const session = window.localStorage.getItem('woo-session')
    if (session) {

        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                'woocommerce-session': `Session ${session}`,
            },
        }))
    }

    return forward(operation)
})

/**
 * Session handler - catch the incoming session token and store it in localStorage, for future GraphQL requests.
 *
 * @ref https://github.com/wp-graphql/wp-graphql-woocommerce/pull/88
 */
export const wooSessionAfterware = new ApolloLink((operation, forward) => {
    // Don't run this while Gatsby is building. Window is not defined
    // @ref: https://www.gatsbyjs.org/docs/debugging-html-builds/
    if (typeof window === 'undefined') {
        return forward(operation)
    }

    return forward(operation).map(response => {
        const context = operation.getContext()

        const {
            response: { headers },
        } = context

        const session = headers.get('woocommerce-session')

        // Bail if no session was sent
        if (!session) {
            return response
        }

        // Bail if we already have the session
        if (window.localStorage.getItem('woo-session') === session) {
            return response
        }

        // Set WC session to localStorage
        window.localStorage.setItem('woo-session', session)

        return response
    })
})

export const client = new ApolloClient({
    cache: new InMemoryCache({
        typePolicies: {
            Cart: {
                keyFields: ['total'], // cart doesn't have an id, so per docs we need to track it somehow to make cache work as expected. This warning came up during coupons on the cart page
            },
        },
    }),
    link: from([wooSessionMiddleware, wooSessionAfterware, httpLink]),
})
